import { Cookies } from '@saatva-bits/pattern-library.utils.cookies'

export const PRISMIC_PREVIEW_REF = 'prismicPreviewRef'

export const getPrismicRef = (req) => { return Cookies.get(PRISMIC_PREVIEW_REF, req) }

export const setPrismicHeader = (prismicRef) => {
    return prismicRef ? { 'prismic-ref': prismicRef } : {}
}

export const getPreviewCacheKey = (prismicRef) => {
    if (!prismicRef) {
        return null
    }

    try {
        const start = prismicRef.search('previews/') + 9
        const end = prismicRef.indexOf('?websitePreviewId')
        const previewId = prismicRef.slice(start, end)
        return `preview:${previewId}`
    } catch (error) {
        console.error(`[Util.Prismic.getPreviewCacheKey]: Failed to parse prismic preview ref with message: ${error.message}`)
        return prismicRef
    }
}
