import { setPrismicHeader } from '@/utils/prismicPreview'

const API_PATH = `${process.env.NEXT_PUBLIC_BASE_PATH}/api`

export const fetchApi = async ( path, body = null, method = 'GET') => {
    let finalBody = body

    if (method !== 'GET' && body) {
        finalBody = {
            body: JSON.stringify(body)
        }
    }

    const response = await fetch(`${API_PATH}/${path}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json'
        },
        ...finalBody
    })

    const responseData = await response.json()

    return responseData
}

/**
   * Client-side fetch a proxied API route for one of our microservices: Catalog, Cart, Content
   * */
export const fetchGraphQlApi = async (path, query, variables, prismicRef) => {
    try {
        const graphqlUri = `${API_PATH}${path}`

        const fetchOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                /* Add encoding or other headers if desired */
                // Compress the response, otherwise the query will take a long time.
                'Accept-Encoding': `gzip, deflate, br`
            },
            body: JSON.stringify({
                query,
                variables: variables || {}
            })
        }

        if (prismicRef) {
            fetchOptions.headers = {
                ...fetchOptions.headers,
                ...setPrismicHeader(prismicRef)
            }
        }

        const response = await fetch(graphqlUri, fetchOptions)
        const { data } = await response.json()

        return data
    } catch (error) {
        throw new Error(error)
    }
}